<template>
  <v-radio-group
    :value="value"
    class="pa-0 ma-0"
    @input="$emit('input', $event)"
    v-on="$listeners"
  >
    <slot class="ma-0" />
  </v-radio-group>
</template>

<script>
export default {
  name: 'FormRadioGroup',
  props: {
    value: {
      type: String,
      default: ''
    }
  }
};
</script>

<style></style>
